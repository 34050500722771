import React, { useEffect, useRef, useState } from 'react';
import { Button, Result, Spin, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { exchangeOAuthCode } from 'api/oAuth';
import { IConnection } from 'models/connection';

export const OAuthCallback: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [connection, setConnection] = useState<IConnection>();
    const [error, setError] = useState<any>();
    const [searchParams] = useSearchParams();
    const ref = useRef(false);

    useEffect(() => {
        console.log(searchParams.toString());
    }, [searchParams]);

    useEffect(() => {
        if (ref.current || loading || !searchParams.get('code')) {
            return;
        }

        ref.current = true;

        console.log('running');

        setLoading(true);

        exchangeOAuthCode({
            code: searchParams.get('code')!,
            state: searchParams.get('state')!,
            sessionState: searchParams.get('session_state')!,
        }).then((conn) => {
            setConnection(conn);
        }).catch((e) => setError(e)).finally(() => setLoading(false));

    // intentionally don't want it to run again
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (searchParams.get('error')) {
        const description = searchParams.get('error_description');

        return (
            <Result
                status="error"
                title="Authorization Failed"
                subTitle="Sadly, creating the connection failed."
                extra={[
                    <Button type="primary" key="back" onClick={() => navigate('/connections')}>
                        Go Back
                    </Button>
                ]}
            >
                <div className="desc">
                    <Typography.Paragraph>
                        { !description
                            ? 'No further details were provided. Try again later.'
                            : description.split('\r\n').map((l, i) => {
                                return (
                                    <React.Fragment key={`details-${ i }`}>
                                        { i === 0 ? null : <br /> }
                                        { l }
                                    </React.Fragment>
                                );
                            })
                        }
                    </Typography.Paragraph>
                </div>
            </Result>
        );
    }

    if (!ref.current || loading) {
        return (
            <Spin />
        );
    }

    if (error || !connection) {
        console.log('error', error, 'connection:', connection);
        return (
            <p>An error occurred</p>
        );
    }

    return (
        <Result
            status="success"
            title={`Successfully authentication for: ${ connection.name }`}
            subTitle={`You have successfully connected the authentication for ${ connection.name } and it is ready to be tested`}
            extra={[
                <Button type="primary" key="to-connections" onClick={() => navigate('/connections')}>
                    Go to Connections
                </Button>
            ]}
        />
    );
}

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthenticatedLayout } from 'layout/AuthenticatedLayout';
import { ToolList } from 'views/Tools';
import { ToolDetail } from 'views/Tools/Tool/Detail';
import { ToolNewFlow } from 'views/Tools/New';
import { ToolUpdateFlow } from 'views/Tools/Update';
import { OAuthCallback } from 'views/oAuth';
import { ConnectionsList } from 'views/Connections';

import { UnauthenticatedLayout } from 'layout/UnauthenticatedLayout';
import { Register } from 'views/Auth/Register';
import { Login } from 'views/Auth/Login';

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" element={<UnauthenticatedLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
        </Route>

        <Route path="/" element={<AuthenticatedLayout />}>
            <Route path="tools" element={<ToolList />} />
            <Route path="tools/new" element={<ToolNewFlow />} />
            <Route path="tools/update/:toolId" element={<ToolUpdateFlow />} />
            <Route path="tools/:toolId" element={<ToolDetail />} />

            <Route path="connections" element={<ConnectionsList />} />
            <Route path="connections/callback" element={<ConnectionsList />} />

            <Route path="oauth/callback" element={<OAuthCallback />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

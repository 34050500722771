import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Divider, Button } from 'antd';

import { TEST_TOOL_ID } from 'utils/constants';

export const MyTestToolView: React.FC = () => {
    const navigate = useNavigate();

    const onStartClick = () => {
        navigate(`/tools/${ TEST_TOOL_ID }`);
    };

    return (
        <React.Fragment>
            <Divider orientation="left">Utility Area</Divider>

            <Card
                style={{ maxWidth: '300px' }}
                bordered={false}
                actions={[ <Button key="start-btn" onClick={onStartClick}>Start Testing</Button> ]}
            >
                <Card.Meta
                    title="Test Tool"
                    description="Use this feature to easily test and develop your tool."
                />
            </Card>
        </React.Fragment>
    );
}

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer } from '@ant-design/pro-components';
import { InboxOutlined } from '@ant-design/icons';
import { BreadcrumbProps, Button, Card, Col, Form, Input, notification, Row, Upload } from 'antd';
import { useRequest } from 'ahooks';

import { breadCrumbItemRender } from 'components/helpers/breadcrumbs';

import { getToolById, postNewToolVersion } from 'api/tools';

import { formItemLayout } from '../New/formHelpers';

interface IToolUpdateFormValues {
    file: File;
    entryFile: string;
    version: string;
}

export const ToolUpdateFlow: React.FC = () => {
    const { toolId } = useParams();
    const { data: tool, loading } = useRequest(getToolById, { defaultParams: [toolId || '']});
    const [isSaving, setSaving] = useState(false);
    const [form] = Form.useForm<IToolUpdateFormValues>();
    const [file, setFile] = useState<File>();
    const navigate = useNavigate();

    const breadcrumbProps: BreadcrumbProps = {
        itemRender: breadCrumbItemRender,
        routes: [
            {
                path: `/`,
                breadcrumbName: 'Dashboard',
            },
            {
                path: `/tools`,
                breadcrumbName: `Tools`,
            },
            {
                path: `/tools/update/${ toolId }`,
                breadcrumbName: `Update ${ loading || !tool ? 'Loading...' : tool.name }`,
            },
        ],
    };

    const beforeUpload = (file: File) => {
        setFile(file);

        return false;
    };

    const onCancelClick = () => {
        navigate(-1);
    };

    const onNextClick = async () => {
        setSaving(true);

        try {
            const values = await form.validateFields();

            if (!tool || !tool.id) {
                throw new Error('no tool attached! invalid state');
            }

            if (!file) {
                throw new Error('no file uploaded yet');
            }

            const data = new FormData();
            data.set('file', file);
            data.set('entryFile', values.entryFile);
            data.set('version', values.version);

            const res = await postNewToolVersion(data, tool.id);
            console.log('result:', res);

            navigate('/tools?tab=mine');
        } catch (e) {
            console.warn('error:', e);

            if (e && (e as any).message) {
                notification.error({ message: (e as any).message });
            }

            setSaving(false);
        }
    };

    return (
        <PageContainer
            breadcrumb={breadcrumbProps}
            loading={loading}
        >
            <Card>
                <Form<IToolUpdateFormValues> form={form} layout="horizontal" labelWrap { ...formItemLayout }>
                    <Form.Item name="version" label="Version" extra="Provide the version of your Tool." rules={[{ required: true }]}>
                        <Input placeholder="1.0.0" disabled={loading || isSaving} />
                    </Form.Item>

                    <Form.Item
                        name="file"
                        label="Tool Package File"
                        extra="A flat file zip package with the entry file (ex: index.html) at the top level."
                        valuePropName="file"
                        rules={[{ required: true }]}
                    >
                        <Upload.Dragger
                            beforeUpload={beforeUpload}
                            directory={false}
                            multiple={false}
                            maxCount={1}
                            accept=".zip"
                            disabled={loading || isSaving}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                        </Upload.Dragger>
                    </Form.Item>

                    <Form.Item name="entryFile" label="Tool Entry Point" extra="The entry point for your tool is the default html file. This usually is the index.html file." rules={[{ required: true }]}>
                        <Input placeholder="ex: index.html" disabled={loading || isSaving} />
                    </Form.Item>
                </Form>

                <Row>
                    <Col push={1}>
                        <Button.Group>
                            <Button onClick={onCancelClick} disabled={loading || isSaving}>Cancel</Button>
                            <Button type="primary" onClick={onNextClick} disabled={loading || isSaving} loading={isSaving}>Next</Button>
                        </Button.Group>
                    </Col>
                </Row>
            </Card>
        </PageContainer>
    );
}

import React, { useState } from 'react';
import { PageContainer } from '@ant-design/pro-components';
import { BreadcrumbProps, Row, Col, Steps, Card } from 'antd';

import { breadCrumbItemRender } from 'components/helpers/breadcrumbs';
import { ITool } from 'models/tool';

import { ToolNewFlowStep1 } from './step1';
import { ToolNewFlowStep2 } from './step2';
import { ToolNewFlowStep3 } from './step3';

export const ToolNewFlow: React.FC = () => {
    const [step, setStep] = useState(0);
    const [isSaving, setSaving] = useState(false);
    const [tool, setTool] = useState<ITool>();

    const breadcrumbProps: BreadcrumbProps = {
        itemRender: breadCrumbItemRender,
        routes: [
            {
                path: `/`,
                breadcrumbName: 'Dashboard',
            },
            {
                path: `/tools`,
                breadcrumbName: `Tools`,
            },
            {
                path: '/tools/new',
                breadcrumbName: 'New Tool',
            },
        ],
    };

    const getCurrentStep = () => {
        switch (step) {
            case 0:
                return <ToolNewFlowStep1
                    isSaving={isSaving}
                    setSaving={setSaving}
                    onNextClick={onNextClick}
                    tool={tool}
                    setTool={setTool}
                />
            case 1:
                return <ToolNewFlowStep2
                    isSaving={isSaving}
                    setSaving={setSaving}
                    onNextClick={onNextClick}
                    onPreviousClick={onPreviousClick}
                    tool={tool}
                    setTool={setTool}
                />
            case 2:
                return <ToolNewFlowStep3
                    tool={tool}
                />
        }
    };

    const onPreviousClick = () => {
        setSaving(false);
        setStep(step === 0 ? 0 : step - 1);
    };

    const onNextClick = () => {
        setSaving(false);
        setStep(step >= 2 ? 2 : step + 1);
    };

    return (
        <PageContainer
            breadcrumb={breadcrumbProps}
        >
            <Card>
                <Row>
                    <Col span="24">
                        <Steps
                            type="navigation"
                            size="small"
                            current={step}
                        >
                            <Steps.Step
                                title="Step 1"
                                description="Enter basic info here."
                                status={step === 0 && isSaving ? "process" : "finish"}
                            />

                            <Steps.Step
                                title="Step 2"
                                status="finish"
                                description="Upload the tool files."
                            />

                            <Steps.Step
                                title="Step 3"
                                status="finish"
                                description="Submit the tool."
                            />
                        </Steps>
                    </Col>
                </Row>

                <div style={{ marginTop: '25px' }}>
                    { getCurrentStep() }
                </div>
            </Card>
        </PageContainer>
    );
}

import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { NavLink } from "react-router-dom";

export const breadCrumbItemRender = (route: Route, params: any, routes: Route[], paths: string[]) => {
    const last = routes.indexOf(route) === routes.length - 1;

    if (last) {
        return (
            <span>{route.breadcrumbName}</span>
        );
    }

    return (
        <NavLink to={route.path}>{route.breadcrumbName}</NavLink>
    );
};

import React from 'react';
import { Outlet } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';

import './unauthenticated.css';

export const UnauthenticatedLayout: React.FC = () => {
    return (
        <Layout className="unauthenticated-layout container">
            <Row justify="center" className="content">
                <Col xs={22} sm={16} md={10} lg={6}>
                    <Outlet />
                </Col>
            </Row>

            <Layout.Footer>
                <div className="footer">Copyright &copy; {new Date().getFullYear()} Bleau Consulting. All rights reserved.</div>
            </Layout.Footer>
        </Layout>
    );
}

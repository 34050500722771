import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { ITool } from 'models/tool';

import { postNewToolStep1, updateNewToolStep1 } from 'api/tools';
import { gaEvent } from 'utils/ga';

import { formItemLayout } from './formHelpers';

interface IToolNewFormStep1Values {
    name: string;
    description: string;
}

interface IToolNewFlowStep1Props {
    onNextClick: () => void;
    setSaving: (value: boolean) => void;
    isSaving: boolean;
    setTool: (tool: ITool) => void;
    tool?: ITool;
}

export const ToolNewFlowStep1: React.FC<IToolNewFlowStep1Props> = (props) => {
    const [form] = Form.useForm<IToolNewFormStep1Values>();

    const onNextClick = async () => {
        props.setSaving(true);

        try {
            const values = await form.validateFields();
            console.log('values:', values);

            let res: ITool;
            if (props.tool?.id) {
                res = await updateNewToolStep1(values, props.tool.id);
            } else {
                res = await postNewToolStep1(values);
                gaEvent('tool_create', { tool_id: res.id, tool_name: res.name });
            }

            console.log('result:', res);

            props.setTool(res);
            props.onNextClick();
        } catch (e) {
            console.warn('error:', e);
            props.setSaving(false);
        }
    };

    return (
        <React.Fragment>
            <Form<IToolNewFormStep1Values>
                form={form}
                layout="horizontal"
                labelWrap
                initialValues={{
                    name: props.tool?.name,
                    description: props.tool?.description,
                }}
                { ...formItemLayout }
            >
                <Form.Item name="name" label="Tool Name" extra="A friendly yet useful name for your tool, to be seen and reference publicly." rules={[{ required: true }]}>
                    <Input placeholder="Your tool's name here" disabled={props.isSaving} />
                </Form.Item>

                {/* Do we want to support Markdown? */}
                <Form.Item name="description" label="Description" extra="Provide a detailed description of what your tool does and what people can accomplish using it." rules={[{ required: true }]}>
                    <Input.TextArea placeholder="TODO: very useful text goes here." disabled={props.isSaving} />
                </Form.Item>
            </Form>

            <Row>
                <Col push={1}>
                    <Button.Group>
                        <Button type="primary" onClick={onNextClick} disabled={props.isSaving} loading={props.isSaving}>Next</Button>
                    </Button.Group>
                </Col>
            </Row>
        </React.Fragment>
    );
}

import React, { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined, WarningTwoTone } from '@ant-design/icons';
import { BreadcrumbProps, Button, Empty, Table, notification, message, Modal } from 'antd';
import { PageContainer } from '@ant-design/pro-components';
import { ColumnProps } from 'antd/lib/table';

import { IConnection } from 'models/connection';

import { breadCrumbItemRender } from 'components/helpers/breadcrumbs';
import { SimpleDate } from 'components/helpers/date';
import { ActionMenuItemMap, ActionsMenu } from 'components/actions';
import { getConnections, deleteConnection } from 'api/connections';

import { NewConnectionDrawer } from './NewConnection';

export const ConnectionsList: React.FC = () => {
    const [newDrawerVisible, setNewDrawerVisible] = useState(false);
    const { data, error, loading, run } = useRequest(getConnections, { loadingDelay: 300 });

    useEffect(() => {
        if (!error) {
            return;
        }

        notification.error({
            message: 'Failed to load Connections',
            description: error.message || 'Unknown error occured',
            duration: 0,
            btn: <Button type="primary" size="small" onClick={run}>Retry</Button>,
        });
    }, [error, run]);

    const connectionActions: ActionMenuItemMap<IConnection> = {
        edit: { icon: <EditOutlined />, text: 'Edit', disabled: () => true },
        delete: { icon: <DeleteOutlined />, text: 'Delete' },
    };

    const onActionClick = async (connection: IConnection, what: string) => {
        switch (what) {
            case 'delete':
                Modal.confirm({
                    title: `Delete: ${ connection.name }`,
                    icon: <ExclamationCircleOutlined />,
                    content: 'Removing this connection will prevent future usage. Are you sure you wish to continue?',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    async onOk() {
                        try {
                            await deleteConnection(connection.id);
                            message.success(`Successfully removed the connection: ${connection.name}`);
                            run();
                        } catch (e: any) {
                            console.warn('error removing:', e);
                            message.error(`Failed to remove the connection: ${ e.message ? e.message : 'unknown reason'}`);
                        }
                    },
                });
                break;
            default:
                message.error(`Unknown action menu item: ${what}`);
                break;
        }
    }

    const columns: ColumnProps<IConnection>[] = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Description', dataIndex: 'description', key: 'desc', ellipsis: true, render: (desc: string) => desc || '-' },
        { title: 'Url', key: 'server', ellipsis: true, render: (_, c) => `${c.subdomain}.${c.region}` },
        { title: 'Connected?', dataIndex: 'connected', key: 'connected', render: (value: boolean) => value ? 'Yes' : 'No' },
        { title: 'Last Used', dataIndex: 'lastUsedAt', key: 'lastUsedAt', render: (value: string) => <SimpleDate date={value} simpler /> },
        { title: 'Actions', key: 'actions', render: (_, c) => <ActionsMenu<IConnection> record={c} actions={connectionActions} onClick={onActionClick} /> },
    ];

    const breadcrumbProps: BreadcrumbProps = {
        itemRender: breadCrumbItemRender,
        routes: [
            {
                path: `/`,
                breadcrumbName: 'Dashboard',
            },
            {
                path: `/connections`,
                breadcrumbName: `Connections`,
            },
        ],
    };

    const onDrawerClose = () => {
        run();
        setNewDrawerVisible(false);
    };

    let emptyText = <Empty description="Please create a connection to an environment." />;
    if (error) {
        emptyText = (
            <Empty
                description={<span>Failed to load the connections:<br />{error.message || 'unknown cause'}</span>}
                image={<WarningTwoTone twoToneColor="#b11616" />}
                imageStyle={{ fontSize: '75px' }}
            />
        );
    }

    return (
        <PageContainer
            breadcrumb={breadcrumbProps}
            extra={[
                <Button type="primary" onClick={() => setNewDrawerVisible(true)} icon={<PlusOutlined />}>New</Button>
            ]}
        >
            <Table<IConnection>
                columns={columns}
                dataSource={data}
                rowKey="id"
                loading={loading}
                locale={{ emptyText }}
            />

            <NewConnectionDrawer visible={newDrawerVisible} onClose={onDrawerClose} />
        </PageContainer>
    );
}

import decoder from 'jwt-decode';
import { Mutex } from 'async-mutex';

import { JwtToken } from 'models/user';

const getMutex = new Mutex();

//#region jwt token items
export const jwtTokenKey = 'PowerToolsAuth';
export const jwtExpiresAtKey = 'PowerToolsAuthExpiresAt';
export const jwtUserId = 'PowerToolsAuthUserId';

export async function getJwtToken(): Promise<string> {
    return await getMutex.runExclusive(() => {
        let token = window.sessionStorage.getItem(jwtTokenKey);
        let expiresAtString = window.sessionStorage.getItem(jwtExpiresAtKey);

        if (window.localStorage.getItem(jwtTokenKey)) {
            token = window.localStorage.getItem(jwtTokenKey);
            expiresAtString = window.localStorage.getItem(jwtExpiresAtKey);
        }

        if (!token || !expiresAtString) {
            const s = new URLSearchParams(window.location.search.replace('?', ''));
            if (!s.has('token')) {
                return '';
            }

            const newExpiresAt = storeToken(s.get('token')!, false);
            if (newExpiresAt.length === 0) {
                return '';
            }

            token = s.get('token');
            expiresAtString = newExpiresAt;
            window.location.search = '';
        }

        const expiresAt = new Date(JSON.parse(expiresAtString));
        if (new Date().getTime() < expiresAt.getTime()) {
            return token!;
        } else {
            console.log('auth token has expired');
            revokeToken();
        }

        return '';
    });
}

export async function getBearerToken(): Promise<{ Authorization: string }> {
    const token = await getJwtToken();
    if (!token) {
        return {
            Authorization: '',
        };
    }

    return {
        Authorization: `Bearer ${ token }`,
    };
}

export function storeToken(token: string, permanent: boolean): string {
    const jwt = decoder<JwtToken>(token);

    const expiresAt = new Date(jwt.exp * 1000);
    if (new Date().getTime() > expiresAt.getTime()) {
        console.log('the token to store has expired?!!');
        return '';
    }

    const userId = (jwt as any).userId;

    if (permanent) {
        window.localStorage.setItem(jwtTokenKey, token);
        window.localStorage.setItem(jwtExpiresAtKey, JSON.stringify(expiresAt));
        window.localStorage.setItem(jwtUserId, userId);
    } else {
        window.sessionStorage.setItem(jwtTokenKey, token);
        window.sessionStorage.setItem(jwtExpiresAtKey, JSON.stringify(expiresAt));
        window.sessionStorage.setItem(jwtUserId, userId);
    }

    return JSON.stringify(expiresAt);
}

export function revokeToken(): void {
    window.localStorage.removeItem(jwtTokenKey);
    window.localStorage.removeItem(jwtExpiresAtKey);

    window.sessionStorage.removeItem(jwtTokenKey);
    window.sessionStorage.removeItem(jwtExpiresAtKey);
}

export function storeTokenFromResult(accessToken: string, rememberThem: boolean) {
    storeToken(accessToken, rememberThem);
}

export async function hasValidToken(): Promise<boolean> {
    return !!await getJwtToken();
}

export function getUserId(): string | null {
    let userId = window.sessionStorage.getItem(jwtUserId);

    if (window.localStorage.getItem(jwtUserId)) {
        userId = window.localStorage.getItem(jwtUserId);
    }

    return userId;
}
//#endregion

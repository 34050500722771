export function gaEvent(eventName: string, parameters?: { [key: string]: any }) {
    if (typeof (window as any).gtag !== 'function') {
        return;
    }

    (window as any).gtag('event', eventName, parameters);
}

export function gaSetUserId(userId: string) {
    if (typeof (window as any).gtag !== 'function') {
        return;
    }

    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    (window as any).gtag('config', 'G-1YNBGFE1ET', {
        'user_id': userId,
    });
}

import type { ColProps } from 'antd';

export const formItemLayout: { labelCol: ColProps, wrapperCol: ColProps } = {
    labelCol: {
        xs: { span: 6 },
        sm: { span: 7 },
        md: { span: 5 },
        xl: { span: 3 },
    },
    wrapperCol: {
        xs: { span: 18 },
        sm: { span: 17 },
        md: { span: 13 },
        xl: { span: 10 },
    },
};

import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { ProLayout } from '@ant-design/pro-components';

import { authenticatedMenuData, menuItemRender } from 'components/layout/menu';
import { useToken } from 'hooks/useToken';
import { gaSetUserId } from 'utils/ga';

export const AuthenticatedLayout: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [hasValidToken, isLoadingToken, userId] = useToken();

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') {
            console.log(location, `is loading:`, isLoadingToken, `has valid token:`, hasValidToken);
        }

        // if we aren't loading the initial state of the token
        // and they don't have a valid token
        // then redirect them to the login page
        if (!isLoadingToken && !hasValidToken) {
            const previous = `?previous=${encodeURIComponent(location.pathname + location.search)}`;

            //remove any other notifications, this way only the logged in message shows up
            notification.destroy();

            notification.warning({
                message: 'Unauthorized',
                description: 'You must be logged in to access this page',
                duration: 8,
            });

            navigate(`/auth/login${previous}`);
        }

    }, [location, navigate, isLoadingToken, hasValidToken]);

    useEffect(() => {
        if (!userId) {
            return;
        }

        gaSetUserId(userId);
    }, [userId]);

    const menuFooterRender = (props?: { collapsed?: boolean }): React.ReactNode => {
        if (props?.collapsed) {
            return null;
        }

        return (
            <div
                style={{
                    textAlign: 'center',
                    paddingBlockStart: 12,
                    color: 'rgba(255, 255, 255, 0.65)',
                }}
            >
                <div>Copyright &copy; {new Date().getFullYear()}</div>
                <div>by Bleau Consulting</div>
            </div>
        );
    }

    return (
        <ProLayout
            logo={`${ process.env.PUBLIC_URL }/logo.png`}
            title="Power Tools"
            locale="en-US"
            breakpoint="md"
            layout="side"
            menuDataRender={authenticatedMenuData}
            menuItemRender={menuItemRender}
            loading={isLoadingToken || !hasValidToken}
            menuFooterRender={menuFooterRender}
        >
            <Outlet />
        </ProLayout>
    );
}

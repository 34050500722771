import React from 'react';
import { MenuDataItem } from '@ant-design/pro-components';
import { ApiOutlined, AppstoreOutlined, DashboardOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

export function authenticatedMenuData(): MenuDataItem[] {
    return [
        {
            path: '/',
            name: 'Dashboard',
            icon: <DashboardOutlined />,
        },
        {
            path: '/tools?tab=all',
            name: 'Tools',
            icon: <AppstoreOutlined />,
        },
        {
            path: '/connections',
            name: 'Connections',
            icon: <ApiOutlined />,
        },
    ];
};

export function menuItemRender(item: MenuDataItem): React.ReactNode {
    return (
        <NavLink to={item.path || '/404?error=path-missing'} className="ant-pro-menu-item">
            { typeof item.icon !== 'undefined' ? item.icon : null }
            <span className="ant-pro-menu-item-title">{ item.name || '???' }</span>
        </NavLink>
    );
};

import React, { useState } from 'react';
import { Avatar, Image, Button, Checkbox, Form, Input, Layout } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { loginUser } from 'api/auth';
import { storeToken } from 'utils/auth';

interface ILoginFormValues {
    email: string;
    password: string;
    rememberMe: boolean;
}

export const Login: React.FC = () => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm<ILoginFormValues>();
    const location = useLocation();
    const navigate = useNavigate();

    const onFormFinish = async (values: ILoginFormValues) => {
        setSubmitting(true);

        try {
            const res = await loginUser({ email: values.email, password: values.password });

            storeToken(res.token, values.rememberMe);

            const query = new URLSearchParams(location.search);
            if (location.search && query.has('previous')) {
                navigate(query.get('previous')!);
            } else {
                navigate('/tools');
            }
        } catch (e) {
            console.warn('failed to login', e);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Layout.Content className="login">
            <Layout className="branding">
                <Avatar src={<Image src={`${ process.env.PUBLIC_URL }/logo.png`} />} /> &nbsp; <span className="name">Power Tools</span>
            </Layout>

            <h3>Login</h3>

            <Form
                form={form}
                onFinish={onFormFinish}
            >
                <Form.Item name="email" rules={[{ required: true, message: 'A valid email is required to login.' }]}>
                    <Input
                        size="large"
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        inputMode="email"
                        prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                        disabled={submitting}
                    />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true, message: 'A non-empty password is required to login.' }]}>
                    <Input.Password
                        size="large"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                        disabled={submitting}
                    />
                </Form.Item>

                <Form.Item>
                    <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Link to="/auth/forgot-password" className="text-action">Forgot your password?</Link>
                </Form.Item>

                <Form.Item>
                    <div className="action-buttons">
                        <Button size="large" type="primary" htmlType="submit" className="submit" disabled={submitting} loading={submitting}>Login</Button>
                        <Button size="large" className="sign-up" onClick={() => navigate('/auth/register')} disabled={submitting}>Sign Up</Button>
                    </div>
                </Form.Item>
            </Form>
        </Layout.Content>
    );
}

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Avatar, Image, Form, Row, Col, Input, Button } from 'antd';
import { Rule } from 'antd/lib/form';

import { registerUser } from 'api/auth';
import { storeToken } from 'utils/auth';

interface IRegisterFormValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export const Register: React.FC = () => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm<IRegisterFormValues>();
    const navigate = useNavigate();

    const onFormFinish = async (values: IRegisterFormValues) => {
        setSubmitting(true);

        try {
            const result = await registerUser(values);

            storeToken(result.token, true);

            navigate('/tools');
        } catch (e) {
            console.warn('error result:', e);
        } finally {
            setSubmitting(false);
        }
    };

    const confirmPasswordRules: Rule[] = [
        { required: true, message: 'A non-empty password is required.' },
        {
            message: 'Passwords must match.',
            validator: async (rule, value) => {
                if (value === form.getFieldValue('password')) {
                    return;
                }

                throw new Error('Passwords must match.');
            },
        },
    ];

    return (
        <Layout.Content className="register">
            <Layout className="branding">
                <Avatar src={<Image src={`${process.env.PUBLIC_URL}/logo.png`} />} /> &nbsp; <span className="name">Power Tools</span>
            </Layout>

            <h3>Register</h3>

            <Form<IRegisterFormValues>
                form={form}
                onFinish={onFormFinish}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item name="firstName" rules={[{ required: true, message: 'Your first name is required' }]}>
                            <Input
                                size="large"
                                placeholder="First name"
                                autoComplete="given-name"
                                disabled={submitting}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item name="lastName" rules={[{ required: true, message: 'Your last name is required' }]}>
                            <Input
                                size="large"
                                placeholder="Last name"
                                autoComplete="family-name"
                                disabled={submitting}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <Form.Item name="email" rules={[{ required: true, message: 'A valid email is required to sign up.' }]}>
                            <Input
                                size="large"
                                type="email"
                                placeholder="Email"
                                autoComplete="email"
                                inputMode="email"
                                disabled={submitting}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <Form.Item name="password" rules={[{ required: true, message: 'A non-empty password is required.' }]}>
                            <Input.Password
                                size="large"
                                type="password"
                                placeholder="Password"
                                autoComplete="new-password"
                                disabled={submitting}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <Form.Item name="confirmPassword" rules={confirmPasswordRules}>
                            <Input.Password
                                size="large"
                                type="password"
                                placeholder="Confirm Password"
                                autoComplete="new-password"
                                disabled={submitting}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <Form.Item>
                            <Button size="large" type="primary" htmlType="submit" className="submit" disabled={submitting} loading={submitting}>Register</Button>
                            <Link to="/auth/login" className="text-action">Already have an account?</Link>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout.Content>
    );
}

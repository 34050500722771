import Connection from './connection';
// @ts-expect-error loader-based input
import CompiledFrameScript from 'compile-code-loader!./frame.ts';
export const BaseOptions = {
    frameContainer: 'body',
    frameClassName: 'websandbox__frame',
    frameSrc: null,
    frameContent: `
<!DOCTYPE html>
<html>
<head><meta charset="UTF-8"></head>
<body></body>
</html>
  `,
    codeToRunBeforeInit: null,
    initialStyles: null,
    baseUrl: null,
    allowPointerLock: false,
    allowFullScreen: false,
    sandboxAdditionalAttributes: ''
};
class Websandbox {
    /**
     * {Constructor}
     * @param localApi
     * @param options
     */
    constructor(localApi, options) {
        this.connection = null;
        this.removeMessageListener = () => { };
        this.validateOptions(options);
        this.options = Object.assign(Object.assign({}, BaseOptions), options);
        this.iframe = this.createIframe();
        this.promise = new Promise(resolve => {
            this.connection = new Connection(this.iframe.contentWindow.postMessage.bind(this.iframe.contentWindow), listener => {
                const sourceCheckListener = (event) => {
                    if (event.source !== this.iframe.contentWindow) {
                        return;
                    }
                    return listener(event);
                };
                window.addEventListener('message', sourceCheckListener);
                this.removeMessageListener = () => window.removeEventListener('message', sourceCheckListener);
            }, { allowedSenderOrigin: 'null' });
            this.connection.setServiceMethods({
                iframeInitialized: () => {
                    return this.connection
                        .setLocalApi(localApi)
                        .then(() => resolve(this));
                }
            });
        });
    }
    /**
     * Creates sandbox instancea
     * @param localApi Api of this side. Will be available for sandboxed code as remoteApi
     * @param options Options of created sandbox
     */
    static create(localApi, options = {}) {
        return new Websandbox(localApi, options);
    }
    validateOptions(options) {
        var _a;
        if (options.frameSrc && (options.frameContent || options.initialStyles || options.baseUrl || options.codeToRunBeforeInit)) {
            throw new Error('You can not set both "frameSrc" and any of frameContent,initialStyles,baseUrl,codeToRunBeforeInit options');
        }
        if ('frameContent' in options && !((_a = options.frameContent) === null || _a === void 0 ? void 0 : _a.includes('<head>'))) {
            throw new Error('Websandbox: iFrame content must have "<head>" tag.');
        }
    }
    _prepareFrameContent(options) {
        var _a, _b;
        let frameContent = (_b = (_a = options.frameContent) === null || _a === void 0 ? void 0 : _a.replace('<head>', `<head>\n<script>${CompiledFrameScript}</script>`)) !== null && _b !== void 0 ? _b : '';
        if (options.initialStyles) {
            frameContent = frameContent
                .replace('</head>', `<style>${options.initialStyles}</style>\n</head>`);
        }
        if (options.baseUrl) {
            frameContent = frameContent
                .replace('<head>', `<head>\n<base href="${options.baseUrl}"/>`);
        }
        if (options.codeToRunBeforeInit) {
            frameContent = frameContent
                .replace('</head>', `<script>${options.codeToRunBeforeInit}</script>\n</head>`);
        }
        return frameContent;
    }
    createIframe() {
        var _a;
        const containerSelector = this.options.frameContainer;
        const container = typeof containerSelector === 'string'
            ? document.querySelector(containerSelector)
            : containerSelector;
        if (!container) {
            throw new Error('Websandbox: Cannot find container for sandbox ' + container);
        }
        const frame = document.createElement('iframe');
        // @ts-expect-error typings error
        frame.sandbox = `allow-scripts ${this.options.sandboxAdditionalAttributes}`;
        frame.allow = `${this.options.allowAdditionalAttributes}`;
        frame.className = (_a = this.options.frameClassName) !== null && _a !== void 0 ? _a : '';
        if (this.options.allowFullScreen) {
            frame.allowFullscreen = true;
        }
        if (this.options.frameSrc) {
            frame.src = this.options.frameSrc;
            container.appendChild(frame);
            return frame;
        }
        frame.setAttribute('srcdoc', this._prepareFrameContent(this.options));
        container.appendChild(frame);
        return frame;
    }
    destroy() {
        this.iframe.remove();
        this.removeMessageListener();
    }
    _runCode(code) {
        return this.connection.callRemoteServiceMethod('runCode', code);
    }
    _runFunction(fn) {
        return this._runCode(`(${fn.toString()})()`);
    }
    run(codeOrFunction) {
        if (codeOrFunction.name) {
            return this._runFunction(codeOrFunction);
        }
        return this._runCode(codeOrFunction);
    }
    importScript(path) {
        return this.connection.callRemoteServiceMethod('importScript', path);
    }
    injectStyle(style) {
        return this.connection.callRemoteServiceMethod('injectStyle', style);
    }
}
export default Websandbox;

import { get, post, postForm, putForm, put } from './index';

import { ITool } from 'models/tool';
import { IToolCreationFirstStepPayload } from 'models/payloads/tools';

export const getTools = (authorId?: string) => get<ITool[]>(`/tools${ authorId ? `?authorId=${ authorId }` : '' }`, true);
export const getToolById = (toolId: string) => get<ITool>(`/tools/${ toolId }`, true);

export const postNewToolStep1 = (payload: IToolCreationFirstStepPayload) => post<IToolCreationFirstStepPayload, ITool>('/tools', payload, true);
export const updateNewToolStep1 = (payload: IToolCreationFirstStepPayload, id: string) => put<IToolCreationFirstStepPayload, ITool>(`/tools/${ id }/step1`, { ...payload, id }, true);
export const putNewToolStep2 = (data: FormData, id: string) => putForm<ITool>(`/tools/${ id }/step2`, data, true);
export const postNewToolVersion = (data: FormData, id: string) => postForm<ITool>(`/tools/${ id }/version`, data, true);

import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { ITool } from 'models/tool';

import { putNewToolStep2 } from 'api/tools';

import { formItemLayout } from './formHelpers';

interface IToolNewFormStep2Values {
    file: File;
    entryFile: string;
    version: string;
}

interface IToolNewFlowStep2Props {
    onNextClick: () => void;
    onPreviousClick: () => void;
    setSaving: (value: boolean) => void;
    isSaving: boolean;
    setTool: (tool: ITool) => void;
    tool?: ITool;
}

export const ToolNewFlowStep2: React.FC<IToolNewFlowStep2Props> = (props) => {
    const [form] = Form.useForm<IToolNewFormStep2Values>();
    const [file, setFile] = useState<File>();

    const beforeUpload = (file: File) => {
        setFile(file);

        return false;
    };

    const onPreviousClick = () => {
        props.onPreviousClick();
    };

    const onNextClick = async () => {
        props.setSaving(true);

        try {
            const values = await form.validateFields();

            if (!props.tool || !props.tool.id) {
                throw new Error('no tool prop attached! invalid prop state');
            }

            if (!file) {
                throw new Error('no file uploaded yet');
            }

            const data = new FormData();
            data.set('file', file);
            data.set('entryFile', values.entryFile);
            data.set('version', values.version);

            const res = await putNewToolStep2(data, props.tool.id);
            console.log('result:', res);

            props.setTool(res);
            props.onNextClick();
        } catch (e) {
            console.warn('error:', e);
            props.setSaving(false);
        }
    };

    //TODO: this and Update/index.tsx share a lot of the same code; let's improve it and remove the duplicate code that was copied and pasted
    return (
        <React.Fragment>
            <Form<IToolNewFormStep2Values> form={form} layout="horizontal" labelWrap { ...formItemLayout }>
                <Form.Item name="version" label="Version" extra="Provide the version of your Tool." rules={[{ required: true }]}>
                    <Input placeholder="1.0.0" disabled={props.isSaving} />
                </Form.Item>

                <Form.Item
                    name="file"
                    label="Tool Package File"
                    extra="A flat file zip package with the entry file (ex: index.html) at the top level."
                    valuePropName="file"
                    rules={[{ required: true }]}
                >
                    <Upload.Dragger
                        beforeUpload={beforeUpload}
                        directory={false}
                        multiple={false}
                        maxCount={1}
                        accept=".zip"
                        disabled={props.isSaving}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                    </Upload.Dragger>
                </Form.Item>

                <Form.Item name="entryFile" label="Tool Entry Point" extra="The entry point for your tool is the default html file. This usually is the index.html file." rules={[{ required: true }]}>
                    <Input placeholder="ex: index.html" disabled={props.isSaving} />
                </Form.Item>
            </Form>

            <Row>
                <Col push={1}>
                    <Button.Group>
                        <Button onClick={onPreviousClick} disabled={props.isSaving}>Previous</Button>
                        <Button type="primary" onClick={onNextClick} disabled={props.isSaving} loading={props.isSaving}>Next</Button>
                    </Button.Group>
                </Col>
            </Row>
        </React.Fragment>
    );
}

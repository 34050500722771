import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserId, hasValidToken } from 'utils/auth';

export function useToken(): [boolean, boolean, string] {
    const [loading, setLoading] = useState(true);
    const [validToken, setHasValidToken] = useState(false);
    const [userId, setUserId] = useState('');
    const location = useLocation();

    useEffect(() => {
        hasValidToken().then((value) => {
            setHasValidToken(value);
            setLoading(false);
            setUserId(getUserId() || '');
        });
    }, [location]);

    return [validToken, loading, userId];
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';

import { AppRouter } from './AppRouter';
import reportWebVitals from './reportWebVitals';
import './index.css';

// import { MsalProvider } from '@azure/msal-react';
// import { PublicClientApplication } from "@azure/msal-browser";
// import { msalConfig } from './views/Connections/authConfig';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <ConfigProvider locale={enUS}>
      {/* <MsalProvider instance={msalInstance}> */}
        <AppRouter />
      {/* </MsalProvider> */}
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

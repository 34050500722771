import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloudUploadOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-components';
import { BreadcrumbProps, Button, Empty, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useRequest } from 'ahooks';

import { ITool } from 'models/tool';
import { SimpleDate } from 'components/helpers/date';
import { breadCrumbItemRender } from 'components/helpers/breadcrumbs';
import { ActionMenuItemMap, ActionsMenu } from 'components/actions';

import { MyTestToolView } from './myTestToolView';

import { getTools } from 'api/tools';
import { getUserId } from 'utils/auth';

enum ToolTab {
    All = 'all',
    Mine = 'mine',
}

export const ToolList: React.FC = () => {
    const location = useLocation();
    const [ activeTabKey, setActiveTabKey ] = useState<ToolTab>(ToolTab.All);
    const { data, error, loading, run } = useRequest(getTools, { manual: true });
    const navigate = useNavigate();

    useEffect(() => {
        const s = new URLSearchParams(location.search.replace('?', ''));

        const toTab = s.get('tab');
        if (!toTab) {
            return;
        }

        if (toTab === activeTabKey) {
            return;
        }

        if (!Object.values(ToolTab).includes(toTab as ToolTab)) {
            return;
        }

        const currentTab = toTab as ToolTab;
        setActiveTabKey(currentTab);

        const userId = getUserId();

        run(currentTab === ToolTab.Mine && userId ? userId : '');

        // this is done because we only care about changing it if the url changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const columns: ColumnProps<ITool>[] = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Description', dataIndex: 'description', key: 'desc', ellipsis: true },
        { title: 'Version', dataIndex: ['latestVersion', 'version'], key: 'version', render: (value: string) => `v${ value }` },
        { title: 'Last Used At', dataIndex: 'lastUsedAt', key: 'lastUsedAt', render: (value: string) => <SimpleDate date={value} /> },
    ];

    const myActions: ActionMenuItemMap<ITool> = {
        edit: { icon: <EditOutlined />, text: 'Edit' },
        update: { icon: <CloudUploadOutlined />, text: 'Upload Update' },
        delete: { icon: <DeleteOutlined />, text: 'Delete', disabled: () => true },
    };

    const onActionClick = async (tool: ITool, what: string) => {
        console.log(`Clicked on '${ what }' for the tool: ${ tool.name }`, tool);

        switch (what) {
            case 'edit':
                return;
            case 'update':
                navigate(`/tools/update/${ tool.id }`);
                return;
        }
    };

    const myColumns: ColumnProps<ITool>[] = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Description', dataIndex: 'description', key: 'desc', ellipsis: true },
        { title: 'Version', dataIndex: ['latestVersion', 'version'], key: 'version', render: (value: string) => `v${ value }` },
        { title: 'Is Public', dataIndex: 'public', key: 'public', render: (value: boolean) => value ? 'Yes' : 'No' },
        { title: 'Actions', key: 'actions', render: (nothing: any, record: ITool) => <ActionsMenu<ITool> record={record} actions={myActions} onClick={onActionClick} /> },
    ];

    const breadcrumbProps: BreadcrumbProps = {
        itemRender: breadCrumbItemRender,
        routes: [
            {
                path: `/`,
                breadcrumbName: 'Dashboard',
            },
            {
                path: `/tools`,
                breadcrumbName: `Tools`,
            },
        ],
    };

    const tabList = [
        { tab: 'All Tools', key: ToolTab.All },
        { tab: 'Tools By Me', key: ToolTab.Mine },
    ];

    const onTabChange = (tabKey: string) => {
        navigate(`/tools?tab=${ tabKey }`);
    };

    const myTestTool = () => {
        if (activeTabKey !== ToolTab.Mine) {
            return null;
        }

        return (
            <MyTestToolView />
        );
    }

    if (error) {
        console.warn('error while fetching the tools:', error, data);
    }

    return (
        <PageContainer
            breadcrumb={breadcrumbProps}
            extra={
                activeTabKey === ToolTab.Mine ?
                    <Button
                        type="primary"
                        onClick={() => navigate('/tools/new')}
                        icon={<PlusOutlined />}
                    >New</Button>
                : null
            }
            tabActiveKey={activeTabKey}
            tabList={tabList}
            onTabChange={onTabChange}
        >
            <Table<ITool>
                columns={activeTabKey === ToolTab.Mine ? myColumns : columns}
                dataSource={data}
                rowKey="id"
                loading={loading}
                locale={{ emptyText: <Empty description="No tools in the system" /> }}
                onRow={(record) => {
                    return {
                        onClick: () => navigate(`/tools/${ record.id }`),
                    };
                }}
            />

            { myTestTool() }
        </PageContainer>
    );
}

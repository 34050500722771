import React, { useState, useEffect } from 'react';
import { Button, Drawer, Form, Input, message, notification, Select, Space } from 'antd';

import { createConnection, initiateConnection } from 'api/connections';
import { IEnvironment } from 'models/connection';

interface INewConnectionFormValues {
    name: string;
    description: string;
    region: string;
    subdomain: string;
    environment: string;
    url: string;
}

interface INewConnectionDrawerProps {
    visible: boolean;
    onClose: () => void;
}

export const NewConnectionDrawer: React.FC<INewConnectionDrawerProps> = (props) => {
    const [form] = Form.useForm<INewConnectionFormValues>();
    const [saving, setSaving] = useState(false);
    let environments: any[] = [];
    const [environmentOptions, setEnvironmentOptions] = useState(environments);
    const [isMicrosoftLoggedIn, setIsMicrosoftLoggedIn] = useState(false);

    const onClose = () => {
        setIsMicrosoftLoggedIn(false);
        setSaving(false);
        props.onClose();
        form.resetFields();
    };

    const onCreate = async () => {
        setSaving(true);

        try {
            const values = await form.validateFields();
            const conn = await createConnection({
                name: values.name?.trim(),
                description: values.description?.trim(),
                subdomain: values.subdomain?.trim(),
                region: values.region?.trim(),
            }).catch((e: any) => {
                console.warn('error creating connection:', e);
                throw e;
            });

            message.success(`Successfully created the connection: ${conn}`);

            onClose();
        } catch (e) {
            setSaving(false);
            console.log('error:', e);
            notification.error({ message: 'Error while creating the connection' });
        }
    };

    useEffect(() => {
        const handleUrlChange = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            const state = urlParams.get("state");
            const storedCodeVerifier = localStorage.getItem("codeVerifier");
            if (code && state && storedCodeVerifier) {
                setIsMicrosoftLoggedIn(true);
                getUserEnvironments(code, storedCodeVerifier);
            }
        };

        window.addEventListener("hashchange", handleUrlChange, false);
        handleUrlChange();

        return () => {
            window.removeEventListener("hashchange", handleUrlChange, false);
        };
    }, []);


    const getUserEnvironments = async (code: string, verifier: string) => {
        const initConnResponse = await initiateConnection({
            code: code,
            verifier: verifier
        }).catch((e: any) => {
            console.warn('error creating connection:', e);
            throw e;
        });

        // Store the environments data instead of the select options
        setEnvironmentOptions(initConnResponse);
    }

    // Utility function to generate a random string
    const generateRandomString = (minLength: number, maxLength: number) => {
        const validChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
        let array = new Uint8Array(length);
        window.crypto.getRandomValues(array);
        array = array.map((x) => validChars.charCodeAt(x % validChars.length));
        return String.fromCharCode.apply(null, Array.from(array));
    };

    // Utility function to generate a code verifier
    const generateCodeVerifier = () => {
        return generateRandomString(43, 128);
    };

    // Utility function to generate a code challenge from the code verifier
    const generateCodeChallenge = async (verifier: string | undefined) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const digest = await window.crypto.subtle.digest("SHA-256", data);
        const base64Digest = btoa(String.fromCharCode(...Array.from(new Uint8Array(digest))));
        const urlSafeDigest = base64Digest.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
        return urlSafeDigest;
    };

    const buildAuthorizationUrl = async () => {
        const verifier = generateCodeVerifier()
        const clientId = process.env.REACT_APP_AAD_APP_CLIENT_ID;
        const redirectUri = encodeURIComponent(process.env.REACT_APP_AAD_APP_REDIRECT_URI || "");
        const responseType = "code";
        const scopes = ['https://globaldisco.crm.dynamics.com/.default'];
        const state = generateRandomString(16, 64);
        const codeChallenge = await generateCodeChallenge(verifier);

        localStorage.setItem("codeVerifier", verifier);

        const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scopes}&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}`;

        return url;
    };

    const useMicrosoftLogin = async () => {
        const authorizationUrl = buildAuthorizationUrl();
        window.location.href = await authorizationUrl;
    };

    const updateForm = (selectedEnvironmentUrl: string) => {
        // Find the selected environment object based on its URL
        const selectedEnvironment = environmentOptions.find((e: IEnvironment) => e.Url === selectedEnvironmentUrl);
        if (selectedEnvironment) {
            form.setFieldsValue({
                name: selectedEnvironment.FriendlyName,
                description: selectedEnvironment.Purpose,
                url: selectedEnvironment.Url,
                subdomain: selectedEnvironment.UrlName,
                region: selectedEnvironment.Url.substring(selectedEnvironment.Url.indexOf(selectedEnvironment.UrlName) + selectedEnvironment.UrlName.length + 1, selectedEnvironment.Url.length),
            });
        }
    }

    return (
        <Drawer
            visible={props.visible || isMicrosoftLoggedIn}
            title="New Connection"
            placement="right"
            onClose={onClose}
            closable={!saving}
            maskClosable={!saving}
            keyboard={false}
            extra={
                <Space>
                    <Button type="primary" onClick={useMicrosoftLogin}>Use Microsoft ID</Button>
                </Space>
            }
        >
            <Form form={form} layout="vertical" initialValues={{
                region: 'crm.dynamics.com',
            }}>
                <Form.Item name="environment" label="Environment" rules={[{ required: true, message: 'Please specify an environment.' }]}>
                    <Select placeholder="Select an environment" onChange={updateForm}>
                        {environmentOptions.map((e: IEnvironment) => (
                            <Select.Option key={e.Url} value={e.Url}>
                                {e.FriendlyName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="name" label="Name" extra="Provide a friendly name for the connection; one that will help you know what it is. (When you select an environment from the list above, this is automatically set for you. Feel free to change it to whatever you'd like!)" rules={[{ required: true, message: 'The name for the connection is required.' }]}>
                    <Input disabled={saving} />
                </Form.Item>

                <Form.Item name="description" label="Description" extra="This field is for you to describe to your future self what this connection is for exactly.">
                    <Input disabled={saving} />
                </Form.Item>

                <Form.Item name="url" label="URL">
                    <Input disabled />
                </Form.Item>
                
                <Form.Item name="subdomain" label="Subdomain">
                    <Input disabled />
                </Form.Item>

                <Form.Item name="region" label="Domain">
                    <Input disabled />
                </Form.Item>
            </Form>
            <Button type="primary" onClick={onCreate} loading={saving} disabled={saving}>Create</Button>
        </Drawer>
    );
}
